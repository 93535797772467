import { usePrepareForTracking } from '~/composables/tracking/usePrepareForTracking';

/**
 * Composable to manage tracking quick order events
 */

export enum FastOrderTabTrackingName {
    manual = 'Manual Input',
    csv = 'CSV-Upload',
    wishlist = 'From a watch list',
    order = 'From previous orders',
}

export enum FastOrderClickButtonName {
    goToCart = 'Go to shopping cart',
    cart = 'Add to shopping cart',
    checkout = 'Checkout',
}

export function useQuickOrderTracking() {
    const gtm = useGtm();

    const { getLoginStatus } = usePrepareForTracking();

    const trackClickOnQuickOrder = () => {
        const event = {
            event: 'quick_order',
            ...getLoginStatus(),
            method: 'header_bar',
        };

        gtm?.trackEvent(event);
    };
    const trackClickOnQuickOrderTabs = (tabName: string, clickButton?: string, noMethod?: boolean) => {
        const event = {
            event: 'quick_order',
            ...getLoginStatus(),
            ...(noMethod ? {} : { method: 'tab selected' }),
            select_content: tabName,
            user_interaction: clickButton,
        };

        gtm?.trackEvent(event);
    };

    return {
        trackClickOnQuickOrder,
        trackClickOnQuickOrderTabs,
    };
}
